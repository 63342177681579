//import { authRoles } from "./auth/authRoles";

export const navigations = [
  {
    name: "Runlist launcher",
    path: "/dashboard/status",
    icon: "dashboard",
  },
  {
    name: "Settings",
    path: "/pages/settings",
    icon: "settings",
  },

  // {
  //   name: "Test",
  //   path: "/dashboard/test",
  //   icon: "dashboard",
  // },
  // {
  //   name: "Live auction",
  //   icon: "format_list_bulleted",
  //   path: "/pages/product-list"
  // }
  /*,
  {
    name: "Products",
    icon: "shopping_cart",
    children: [
      {
        name: "View Product",
        path: "/pages/view-product",
        iconText: "VP",
      },
      {
        name: "New Product",
        path: "/pages/new-product",
        iconText: "NP",
      },
    ],
  }*/
];
