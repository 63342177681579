import React from "react";
import { authRoles } from "../../auth/authRoles";

const dashboardRoutes = [
  {
    path: "/dashboard/status",
    component: React.lazy(() => import("./Status")),
    auth: authRoles.sa,
  },
  {
    path: "/webextest-manheim",
    component: React.lazy(() => import("./Test-Manheim")),
    auth: authRoles.sa,
  },
  {
    path: "/webextest-adesa",
    component: React.lazy(() => import("./Test-Adesa")),
    auth: authRoles.sa,
  },
  {
    path: "/webextest-edge",
    component: React.lazy(() => import("./Test-Adesa")),
    auth: authRoles.sa,
  },
  {
    path: "/dashboard/empty",
    component: React.lazy(() => import("./Empty")),
    auth: authRoles.sa,
  },
  {
    path: "/dashboard/auction-details",
    component: React.lazy(() => import("./AuctionDetails")),
    auth: authRoles.sa,
  },
  /*
  {
    path: "/dashboard/alternative",
    component: React.lazy(() => import("./Analytics")),
    auth: authRoles.sa,
  },
  {
    path: "/dashboard/default",
    component: React.lazy(() => import("./Analytics2")),
    auth: authRoles.admin,
  },
  {
    path: "/dashboard/inventory-management",
    component: React.lazy(() => import("./InventoryManagement")),
    auth: authRoles.admin,
  },*/
];

export default dashboardRoutes;
