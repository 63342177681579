import React, { useState } from "react";
import {
  Card,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import { makeStyles } from "@material-ui/core/styles";
import history from "history.js";
import clsx from "clsx";
import useAuth from 'app/hooks/useAuth';

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    background: "#1A2038",
  },
  card: {
    maxWidth: 800,
    borderRadius: 12,
    margin: "1rem",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const JwtLogin = () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState({
    code: null
  });
  const [message, setMessage] = useState('');
  const [TwoFactorOption, setTwoFactorOption] = useState(true);
  const { sendCode, tfaSelect } = useAuth();

  const classes = useStyles();

  const handleChange = (e) => {
    const value = e.target.value;
  
    if (value.length > 6) {
      setMessage('Code must be 6 digits');
    } else {
      setCode(value);
      setMessage('');
    }
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    try {
      await sendCode(code);
      history.push("/");
    } catch(e) {
      setMessage(e.description);
      setLoading(false);
    }
  };

  const handle2FAOption = async (value) => {
    setLoading(true);

    try {
      await tfaSelect(value)
      setTwoFactorOption(true);
      setLoading(false);
    } catch (e) {
      setMessage(e.description);
      setLoading(false);
    }
    setTwoFactorOption(false)
    }
  


  return (
    <div
      className={clsx(
        "flex justify-center items-center  min-h-full-screen",
        classes.cardHolder
      )}
    >
      <Card className={classes.card}>
        <Grid container>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <div className="p-8 flex justify-center items-center h-full">
              <img
                className="w-200"
                src="/assets/images/illustrations/dreamer.svg"
                alt=""
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className="p-8 h-full bg-light-gray relative">
              <h2 className="mb-4">Manheim Two Factor Authentication</h2>
              {TwoFactorOption ?
                ( <div>
                    <p className="text-14">Choose an option to receive the code</p>
                    <div className='flex justify-between'>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={() => handle2FAOption("email")}
                      >
                        By EMAIL
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={() => handle2FAOption("sms")}
                      >
                        By SMS
                      </Button>
                    </div>
                  </div>
                ) : (
              <ValidatorForm onSubmit={handleFormSubmit}>
                <p className="text-14">Enter the code sent to your phone or SMS</p>
                <TextValidator
                  className={`w-full ${message ? '' : 'mb-6'}`}
                  variant="outlined"
                  size="small"
                  label="Code"
                  onChange={handleChange}
                  type="number"
                  name="code"
                  value={code}
                  validators={["required", "minStringLength:6", "maxStringLength:6"]}
                  errorMessages={["Code is required", "Code must be 6 digits", "Code is required"]}
                />
                
                {message && <p className="text-error">{message}</p>}
                <div className="flex flex-wrap items-center mb-4 mt-4">
                  <div className="relative">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      type="submit"
                    >
                      Sign in
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </ValidatorForm>
              )}
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default JwtLogin;
