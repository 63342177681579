import { useContext } from 'react';
//import AuthContext from 'app/contexts/FirebaseAuthContext';
import AuthContext from 'app/contexts/JWTAuthContext';

const useAuth = () => useContext(AuthContext);

/*
const useAuth = () => {
  return {
    signInWithEmailAndPassword: async (user, password) => {
      console.log(user)
    },
    signInWithGoogle: () => { }
  }
}
*/


export default useAuth;
